<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="pb-2">
    <e-filters
      ref="filters"
      :title="$t('Filtros')"
      :searching="fetching"
      :show-search-button="false"
      @reset="resetFilters"
    >
      <template #after_buttons>
        <e-button
          id="download"
          class="ml-1 btn-sm-block"
          variant="primary"
          icon="download"
          :busy="fetching"
          :text="$t('Baixar Relatório')"
          @click="downloadReport"
        />
        <!-- Button to download report (hidden by default) -->
        <a
          ref="downloadLink"
          style="display: none"
        />
      </template>
      <accounts-payable-filter-form
        :filters="filters"
        :period-is-required="true"
        :period-instruction="$t('O período não deve ser maior do que 90 dias')"
      />
    </e-filters>
  </section>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { formulateValidation, translate } from '@/mixins'
import { EFilters, EButton } from '@/views/components'
import AccountsPayableFilterForm from '../../financial/accounts-payable/components/form/AccountsPayableFilterForm.vue'

export default {
  components: {
    EFilters,
    EButton,
    AccountsPayableFilterForm,
  },

  mixins: [formulateValidation, translate],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapGetters('common/managementAccounts', ['getComboManagementAccounts']),
    ...mapState('pages/financial/accountsPayable', ['filters', 'reportData']),
  },

  mounted() {
    this.fetchManagementAccounts()
  },

  methods: {
    ...mapActions('app', ['fetchCheckingAccounts']),
    ...mapActions('common/managementAccounts', ['fetchManagementAccounts']),
    ...mapActions('pages/financial/accountsPayable', ['generateReport', 'resetFilters']),

    async downloadReport() {
      try {
        if (this.$refs.filters.isValid()) {
          this.fetching = true
          await this.generateReport()
          // Get the download link element from the template
          const { downloadLink } = this.$refs

          if (this.reportData) {
            // Set the URL and download attributes of the link
            downloadLink.href = this.reportData
            downloadLink.download = `Contas_a_Pagar_${moment().format()}.xlsx`

            // Click the link to start the download
            downloadLink.click()
          } else {
            this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>
